exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-afiliados-index-js": () => import("./../../../src/pages/afiliados/index.js" /* webpackChunkName: "component---src-pages-afiliados-index-js" */),
  "component---src-pages-blog-3-platos-rentables-para-un-menu-ejecutivo-js": () => import("./../../../src/pages/blog/3-platos-rentables-para-un-menu-ejecutivo.js" /* webpackChunkName: "component---src-pages-blog-3-platos-rentables-para-un-menu-ejecutivo-js" */),
  "component---src-pages-blog-a-melhor-alternativa-ao-goomer-para-criar-um-menu-digital-gratuito-js": () => import("./../../../src/pages/blog/a-melhor-alternativa-ao-goomer-para-criar-um-menu-digital-gratuito.js" /* webpackChunkName: "component---src-pages-blog-a-melhor-alternativa-ao-goomer-para-criar-um-menu-digital-gratuito-js" */),
  "component---src-pages-blog-a-melhor-alternativa-gratuita-ao-olaclick-para-restaurantes-js": () => import("./../../../src/pages/blog/a-melhor-alternativa-gratuita-ao-olaclick-para-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-a-melhor-alternativa-gratuita-ao-olaclick-para-restaurantes-js" */),
  "component---src-pages-blog-a-melhor-alternativa-gratuita-ao-software-fudo-para-restaurantes-js": () => import("./../../../src/pages/blog/a-melhor-alternativa-gratuita-ao-software-fudo-para-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-a-melhor-alternativa-gratuita-ao-software-fudo-para-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-alegra-para-negocios-js": () => import("./../../../src/pages/blog/alternativa-gratuita-alegra-para-negocios.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-alegra-para-negocios-js" */),
  "component---src-pages-blog-alternativa-gratuita-ao-ifood-para-criar-um-cardapio-digital-js": () => import("./../../../src/pages/blog/alternativa-gratuita-ao-ifood-para-criar-um-cardapio-digital.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-ao-ifood-para-criar-um-cardapio-digital-js" */),
  "component---src-pages-blog-alternativa-gratuita-apprecio-para-administrar-tu-tienda-js": () => import("./../../../src/pages/blog/alternativa-gratuita-apprecio-para-administrar-tu-tienda.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-apprecio-para-administrar-tu-tienda-js" */),
  "component---src-pages-blog-alternativa-gratuita-avocaty-2024-js": () => import("./../../../src/pages/blog/alternativa-gratuita-avocaty-2024.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-avocaty-2024-js" */),
  "component---src-pages-blog-alternativa-gratuita-bakarta-carta-digital-js": () => import("./../../../src/pages/blog/alternativa-gratuita-bakarta-carta-digital.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-bakarta-carta-digital-js" */),
  "component---src-pages-blog-alternativa-gratuita-biolink-js": () => import("./../../../src/pages/blog/alternativa-gratuita-biolink.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-biolink-js" */),
  "component---src-pages-blog-alternativa-gratuita-bistrosoft-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-bistrosoft-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-bistrosoft-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-buena-carta-2023-js": () => import("./../../../src/pages/blog/alternativa-gratuita-buena-carta-2023.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-buena-carta-2023-js" */),
  "component---src-pages-blog-alternativa-gratuita-callbell-shop-vender-en-linea-js": () => import("./../../../src/pages/blog/alternativa-gratuita-callbell-shop-vender-en-linea.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-callbell-shop-vender-en-linea-js" */),
  "component---src-pages-blog-alternativa-gratuita-cluvi-digitaliza-tu-restaurante-js": () => import("./../../../src/pages/blog/alternativa-gratuita-cluvi-digitaliza-tu-restaurante.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-cluvi-digitaliza-tu-restaurante-js" */),
  "component---src-pages-blog-alternativa-gratuita-corntech-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-corntech-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-corntech-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-eleventa-punto-de-venta-js": () => import("./../../../src/pages/blog/alternativa-gratuita-eleventa-punto-de-venta.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-eleventa-punto-de-venta-js" */),
  "component---src-pages-blog-alternativa-gratuita-fuddis-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-fuddis-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-fuddis-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-fudo-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-fudo-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-fudo-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-getjusto-js": () => import("./../../../src/pages/blog/alternativa-gratuita-getjusto.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-getjusto-js" */),
  "component---src-pages-blog-alternativa-gratuita-glop-software-de-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-glop-software-de-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-glop-software-de-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-gloriafood-software-de-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-gloriafood-software-de-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-gloriafood-software-de-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-kyte-2023-js": () => import("./../../../src/pages/blog/alternativa-gratuita-kyte-2023.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-kyte-2023-js" */),
  "component---src-pages-blog-alternativa-gratuita-linktree-js": () => import("./../../../src/pages/blog/alternativa-gratuita-linktree.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-linktree-js" */),
  "component---src-pages-blog-alternativa-gratuita-lizza-venda-en-linea-js": () => import("./../../../src/pages/blog/alternativa-gratuita-lizza-venda-en-linea.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-lizza-venda-en-linea-js" */),
  "component---src-pages-blog-alternativa-gratuita-lnkbio-js": () => import("./../../../src/pages/blog/alternativa-gratuita-lnkbio.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-lnkbio-js" */),
  "component---src-pages-blog-alternativa-gratuita-maxirest-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-maxirest-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-maxirest-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-meitre-reservas-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-meitre-reservas-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-meitre-reservas-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-menupp-menu-digital-js": () => import("./../../../src/pages/blog/alternativa-gratuita-menupp-menu-digital.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-menupp-menu-digital-js" */),
  "component---src-pages-blog-alternativa-gratuita-mesa-247-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-mesa247-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-mesa-247-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-nucleoit-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-nucleoit-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-nucleoit-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-olaclick-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-olaclick-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-olaclick-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-opentable-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-opentable-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-opentable-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-pacto-pos-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-pacto-pos-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-pacto-pos-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-parrot-connect-js": () => import("./../../../src/pages/blog/alternativa-gratuita-parrot-connect.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-parrot-connect-js" */),
  "component---src-pages-blog-alternativa-gratuita-pedidosya-para-negocios-js": () => import("./../../../src/pages/blog/alternativa-gratuita-pedidosya-para-negocios.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-pedidosya-para-negocios-js" */),
  "component---src-pages-blog-alternativa-gratuita-pedix-tienda-en-linea-js": () => import("./../../../src/pages/blog/alternativa-gratuita-pedix-tienda-en-linea.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-pedix-tienda-en-linea-js" */),
  "component---src-pages-blog-alternativa-gratuita-pency-app-vender-en-linea-js": () => import("./../../../src/pages/blog/alternativa-gratuita-pency-app-vender-en-linea.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-pency-app-vender-en-linea-js" */),
  "component---src-pages-blog-alternativa-gratuita-pide-directo-js": () => import("./../../../src/pages/blog/alternativa-gratuita-pide-directo.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-pide-directo-js" */),
  "component---src-pages-blog-alternativa-gratuita-pikotea-software-de-hosteleria-js": () => import("./../../../src/pages/blog/alternativa-gratuita-pikotea-software-de-hosteleria.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-pikotea-software-de-hosteleria-js" */),
  "component---src-pages-blog-alternativa-gratuita-plick-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-plick-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-plick-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-point-punto-de-venta-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-point-punto-de-venta-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-point-punto-de-venta-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-poster-pos-sistema-de-restaurante-js": () => import("./../../../src/pages/blog/alternativa-gratuita-poster-pos-sistema-de-restaurante.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-poster-pos-sistema-de-restaurante-js" */),
  "component---src-pages-blog-alternativa-gratuita-queresto-carta-digital-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-queresto-carta-digital-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-queresto-carta-digital-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-recafy-carta-digital-qr-js": () => import("./../../../src/pages/blog/alternativa-gratuita-recafy-carta-digital-qr.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-recafy-carta-digital-qr-js" */),
  "component---src-pages-blog-alternativa-gratuita-reservandonos-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-reservandonos-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-reservandonos-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-soft-restaurant-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-soft-restaurant-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-soft-restaurant-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-sumer-tienda-en-linea-js": () => import("./../../../src/pages/blog/alternativa-gratuita-sumer-tienda-en-linea.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-sumer-tienda-en-linea-js" */),
  "component---src-pages-blog-alternativa-gratuita-tienda-nube-para-vender-en-linea-js": () => import("./../../../src/pages/blog/alternativa-gratuita-tienda-nube-para-vender-en-linea.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-tienda-nube-para-vender-en-linea-js" */),
  "component---src-pages-blog-alternativa-gratuita-tiendada-tienda-en-linea-js": () => import("./../../../src/pages/blog/alternativa-gratuita-tiendada-tienda-en-linea.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-tiendada-tienda-en-linea-js" */),
  "component---src-pages-blog-alternativa-gratuita-tiendatek-punto-de-venta-js": () => import("./../../../src/pages/blog/alternativa-gratuita-tiendatek-punto-de-venta.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-tiendatek-punto-de-venta-js" */),
  "component---src-pages-blog-alternativa-gratuita-tomato-mx-en-tulum-2023-js": () => import("./../../../src/pages/blog/alternativa-gratuita-tomato-mx-en-tulum-2023.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-tomato-mx-en-tulum-2023-js" */),
  "component---src-pages-blog-alternativa-gratuita-toteat-software-gastronomico-js": () => import("./../../../src/pages/blog/alternativa-gratuita-toteat-software-gastronomico.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-toteat-software-gastronomico-js" */),
  "component---src-pages-blog-alternativa-gratuita-treinta-vender-en-linea-js": () => import("./../../../src/pages/blog/alternativa-gratuita-treinta-vender-en-linea.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-treinta-vender-en-linea-js" */),
  "component---src-pages-blog-alternativa-gratuita-waiterio-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-waiterio-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-waiterio-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-waitry-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-waitry-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-waitry-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-wansoft-software-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-gratuita-wansoft-software-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-wansoft-software-restaurantes-js" */),
  "component---src-pages-blog-alternativa-gratuita-whataform-vender-en-linea-js": () => import("./../../../src/pages/blog/alternativa-gratuita-whataform-vender-en-linea.js" /* webpackChunkName: "component---src-pages-blog-alternativa-gratuita-whataform-vender-en-linea-js" */),
  "component---src-pages-blog-alternativa-menucel-menu-digital-para-restaurantes-js": () => import("./../../../src/pages/blog/alternativa-menucel-menu-digital-para-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-alternativa-menucel-menu-digital-para-restaurantes-js" */),
  "component---src-pages-blog-alternativa-menurama-app-menu-digital-js": () => import("./../../../src/pages/blog/alternativa-menurama-app-menu-digital.js" /* webpackChunkName: "component---src-pages-blog-alternativa-menurama-app-menu-digital-js" */),
  "component---src-pages-blog-best-restaurants-in-arlington-texas-2023-js": () => import("./../../../src/pages/blog/best-restaurants-in-arlington-texas-2023.js" /* webpackChunkName: "component---src-pages-blog-best-restaurants-in-arlington-texas-2023-js" */),
  "component---src-pages-blog-best-restaurants-in-corpus-christi-texas-2023-js": () => import("./../../../src/pages/blog/best-restaurants-in-corpus-christi-texas-2023.js" /* webpackChunkName: "component---src-pages-blog-best-restaurants-in-corpus-christi-texas-2023-js" */),
  "component---src-pages-blog-best-restaurants-in-el-paso-texas-2023-js": () => import("./../../../src/pages/blog/best-restaurants-in-el-paso-texas-2023.js" /* webpackChunkName: "component---src-pages-blog-best-restaurants-in-el-paso-texas-2023-js" */),
  "component---src-pages-blog-best-restaurants-in-irving-texas-2023-js": () => import("./../../../src/pages/blog/best-restaurants-in-irving-texas-2023.js" /* webpackChunkName: "component---src-pages-blog-best-restaurants-in-irving-texas-2023-js" */),
  "component---src-pages-blog-best-restaurants-in-laredo-texas-2023-js": () => import("./../../../src/pages/blog/best-restaurants-in-laredo-texas-2023.js" /* webpackChunkName: "component---src-pages-blog-best-restaurants-in-laredo-texas-2023-js" */),
  "component---src-pages-blog-como-crear-un-menu-digital-con-codigo-qr-js": () => import("./../../../src/pages/blog/como-crear-un-menu-digital-con-codigo-qr.js" /* webpackChunkName: "component---src-pages-blog-como-crear-un-menu-digital-con-codigo-qr-js" */),
  "component---src-pages-blog-como-crear-un-menu-qr-gratis-js": () => import("./../../../src/pages/blog/como-crear-un-menu-qr-gratis.js" /* webpackChunkName: "component---src-pages-blog-como-crear-un-menu-qr-gratis-js" */),
  "component---src-pages-blog-como-criar-um-menu-qr-gratuito-js": () => import("./../../../src/pages/blog/como-criar-um-menu-qr-gratuito.js" /* webpackChunkName: "component---src-pages-blog-como-criar-um-menu-qr-gratuito-js" */),
  "component---src-pages-blog-como-fazer-um-catalogo-digital-gratuito-para-o-seu-negocio-js": () => import("./../../../src/pages/blog/como-fazer-um-catalogo-digital-gratuito-para-o-seu-negocio.js" /* webpackChunkName: "component---src-pages-blog-como-fazer-um-catalogo-digital-gratuito-para-o-seu-negocio-js" */),
  "component---src-pages-blog-como-hacer-el-menu-de-tu-restaurante-en-5-pasos-js": () => import("./../../../src/pages/blog/como-hacer-el-menu-de-tu-restaurante-en-5-pasos.js" /* webpackChunkName: "component---src-pages-blog-como-hacer-el-menu-de-tu-restaurante-en-5-pasos-js" */),
  "component---src-pages-blog-como-hacer-un-catalogo-digital-gratis-para-tu-negocio-js": () => import("./../../../src/pages/blog/como-hacer-un-catalogo-digital-gratis-para-tu-negocio.js" /* webpackChunkName: "component---src-pages-blog-como-hacer-un-catalogo-digital-gratis-para-tu-negocio-js" */),
  "component---src-pages-blog-como-hacer-una-pizza-napolitana-js": () => import("./../../../src/pages/blog/como-hacer-una-pizza-napolitana.js" /* webpackChunkName: "component---src-pages-blog-como-hacer-una-pizza-napolitana-js" */),
  "component---src-pages-blog-como-vender-por-whatsapp-para-restaurantes-js": () => import("./../../../src/pages/blog/como-vender-por-whatsapp-para-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-como-vender-por-whatsapp-para-restaurantes-js" */),
  "component---src-pages-blog-crea-tu-catalogo-de-productos-herbalife-js": () => import("./../../../src/pages/blog/crea-tu-catalogo-de-productos-herbalife.js" /* webpackChunkName: "component---src-pages-blog-crea-tu-catalogo-de-productos-herbalife-js" */),
  "component---src-pages-blog-free-alternative-to-buonmenu-to-create-a-digital-menu-with-qr-js": () => import("./../../../src/pages/blog/free-alternative-to-buonmenu-to-create-a-digital-menu-with-qr.js" /* webpackChunkName: "component---src-pages-blog-free-alternative-to-buonmenu-to-create-a-digital-menu-with-qr-js" */),
  "component---src-pages-blog-how-to-create-a-free-qr-menu-js": () => import("./../../../src/pages/blog/how-to-create-a-free-qr-menu.js" /* webpackChunkName: "component---src-pages-blog-how-to-create-a-free-qr-menu-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-key-responsibilities-of-a-cook-in-the-restaurant-js": () => import("./../../../src/pages/blog/key-responsibilities-of-a-cook-in-the-restaurant.js" /* webpackChunkName: "component---src-pages-blog-key-responsibilities-of-a-cook-in-the-restaurant-js" */),
  "component---src-pages-blog-key-responsibilities-of-a-sommelier-at-the-restaurant-js": () => import("./../../../src/pages/blog/key-responsibilities-of-a-sommelier-at-the-restaurant.js" /* webpackChunkName: "component---src-pages-blog-key-responsibilities-of-a-sommelier-at-the-restaurant-js" */),
  "component---src-pages-blog-key-responsibilities-of-a-waiter-in-the-restaurant-js": () => import("./../../../src/pages/blog/key-responsibilities-of-a-waiter-in-the-restaurant.js" /* webpackChunkName: "component---src-pages-blog-key-responsibilities-of-a-waiter-in-the-restaurant-js" */),
  "component---src-pages-blog-key-responsibilities-of-the-sous-chef-in-the-restaurant-js": () => import("./../../../src/pages/blog/key-responsibilities-of-the-sous-chef-in-the-restaurant.js" /* webpackChunkName: "component---src-pages-blog-key-responsibilities-of-the-sous-chef-in-the-restaurant-js" */),
  "component---src-pages-blog-la-industria-restaurantera-en-mexico-js": () => import("./../../../src/pages/blog/la-industria-restaurantera-en-mexico.js" /* webpackChunkName: "component---src-pages-blog-la-industria-restaurantera-en-mexico-js" */),
  "component---src-pages-blog-los-3-mejores-puntos-de-venta-en-mexico-js": () => import("./../../../src/pages/blog/los-3-mejores-puntos-de-venta-en-mexico.js" /* webpackChunkName: "component---src-pages-blog-los-3-mejores-puntos-de-venta-en-mexico-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-aguascalientes-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-aguascalientes-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-aguascalientes-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-avandaro-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-avandaro-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-avandaro-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-azcapotzalco-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-azcapotzalco-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-azcapotzalco-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-buenos-aires-argentina-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-buenos-aires-argentina.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-buenos-aires-argentina-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-cali-colombia-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-cali-colombia.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-cali-colombia-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-campeche-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-campeche-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-campeche-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-cartagena-bocagrande-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-cartagena-bocagrande-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-cartagena-bocagrande-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-cartagena-centro-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-cartagena-centro-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-cartagena-centro-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-chapultepec-guadalajara-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-chapultepec-guadalajara-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-chapultepec-guadalajara-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-ciudad-de-mexico-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-ciudad-de-mexico.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-ciudad-de-mexico-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-durango-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-durango-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-durango-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-ecuador-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-ecuador.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-ecuador-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-el-palmar-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-el-palmar-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-el-palmar-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-filandia-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-filandia-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-filandia-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-getsemani-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-getsemani-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-getsemani-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-guasave-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-guasave-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-guasave-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-hermosillo-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-hermosillo-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-hermosillo-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-la-macarena-bogota-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-la-macarena-bogota-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-la-macarena-bogota-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-la-plata-argentina-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-la-plata-argentina-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-la-plata-argentina-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-landmark-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-landmark-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-landmark-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-los-mochis-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-los-mochis-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-los-mochis-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-marina-vallarta-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-marina-vallarta-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-marina-vallarta-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-matamoros-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-matamoros-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-matamoros-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-mazatlan-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-mazatlan-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-mazatlan-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-medellin-antioquia-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-medellin-antioquia.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-medellin-antioquia-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-merida-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-merida-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-merida-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-merlo-argentina-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-merlo-argentina-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-merlo-argentina-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-mexicali-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-mexicali-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-mexicali-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-miraflores-lima-peru-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-miraflores-lima-peru.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-miraflores-lima-peru-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-montevideo-uruguay-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-montevideo-uruguay.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-montevideo-uruguay-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-morelia-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-morelia-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-morelia-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-pachuca-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-pachuca-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-pachuca-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-pamplona-espana-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-pamplona-espana-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-pamplona-espana-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-poza-rica-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-poza-rica-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-poza-rica-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-puebla-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-puebla-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-puebla-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-puerto-madero-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-puerto-madero-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-puerto-madero-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-punta-mita-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-punta-mita-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-punta-mita-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-reynosa-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-reynosa-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-reynosa-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-rosario-argentina-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-rosario-argentina-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-rosario-argentina-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-salamanca-gto-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-salamanca-gto-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-salamanca-gto-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-saltillo-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-saltillo-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-saltillo-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-san-andres-islas-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-san-andres-islas-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-san-andres-islas-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-santa-maria-la-ribera-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-santa-maria-la-ribera-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-santa-maria-la-ribera-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-santa-marta-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-santa-marta-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-santa-marta-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-santiago-de-chile-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-santiago-de-chile.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-santiago-de-chile-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-tampico-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-tampico-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-tampico-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-tecamac-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-tecamac-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-tecamac-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-tecolutla-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-tecolutla-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-tecolutla-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-tequesquitengo-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-tequesquitengo-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-tequesquitengo-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-tezontle-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-tezontle-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-tezontle-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-toluca-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-toluca-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-toluca-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-villa-hermosa-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-villa-hermosa-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-villa-hermosa-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-en-zacatecas-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-en-zacatecas-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-en-zacatecas-2023-js" */),
  "component---src-pages-blog-mejores-restaurantes-veganos-en-queretaro-2023-js": () => import("./../../../src/pages/blog/mejores-restaurantes-veganos-en-queretaro-2023.js" /* webpackChunkName: "component---src-pages-blog-mejores-restaurantes-veganos-en-queretaro-2023-js" */),
  "component---src-pages-blog-organigrama-de-un-restaurante-ejemplos-puestos-funciones-js": () => import("./../../../src/pages/blog/organigrama-de-un-restaurante-ejemplos-puestos-funciones.js" /* webpackChunkName: "component---src-pages-blog-organigrama-de-un-restaurante-ejemplos-puestos-funciones-js" */),
  "component---src-pages-blog-ponto-de-venda-de-mantimentos-gratuitos-js": () => import("./../../../src/pages/blog/ponto-de-venda-de-mantimentos-gratuitos.js" /* webpackChunkName: "component---src-pages-blog-ponto-de-venda-de-mantimentos-gratuitos-js" */),
  "component---src-pages-blog-principais-funcoes-do-sous-chef-no-restaurante-js": () => import("./../../../src/pages/blog/principais-funcoes-do-sous-chef-no-restaurante.js" /* webpackChunkName: "component---src-pages-blog-principais-funcoes-do-sous-chef-no-restaurante-js" */),
  "component---src-pages-blog-principales-funciones-de-un-ayudante-de-cocina-en-el-restaurante-js": () => import("./../../../src/pages/blog/principales-funciones-de-un-ayudante-de-cocina-en-el-restaurante.js" /* webpackChunkName: "component---src-pages-blog-principales-funciones-de-un-ayudante-de-cocina-en-el-restaurante-js" */),
  "component---src-pages-blog-principales-funciones-de-un-barman-en-el-restaurante-js": () => import("./../../../src/pages/blog/principales-funciones-de-un-barman-en-el-restaurante.js" /* webpackChunkName: "component---src-pages-blog-principales-funciones-de-un-barman-en-el-restaurante-js" */),
  "component---src-pages-blog-principales-funciones-de-un-cocinero-en-el-restaurante-js": () => import("./../../../src/pages/blog/principales-funciones-de-un-cocinero-en-el-restaurante.js" /* webpackChunkName: "component---src-pages-blog-principales-funciones-de-un-cocinero-en-el-restaurante-js" */),
  "component---src-pages-blog-principales-funciones-de-un-mesero-en-el-restaurante-js": () => import("./../../../src/pages/blog/principales-funciones-de-un-mesero-en-el-restaurante.js" /* webpackChunkName: "component---src-pages-blog-principales-funciones-de-un-mesero-en-el-restaurante-js" */),
  "component---src-pages-blog-principales-funciones-de-un-runner-en-el-restaurante-js": () => import("./../../../src/pages/blog/principales-funciones-de-un-runner-en-el-restaurante.js" /* webpackChunkName: "component---src-pages-blog-principales-funciones-de-un-runner-en-el-restaurante-js" */),
  "component---src-pages-blog-principales-funciones-de-un-sommelier-en-el-restaurante-js": () => import("./../../../src/pages/blog/principales-funciones-de-un-sommelier-en-el-restaurante.js" /* webpackChunkName: "component---src-pages-blog-principales-funciones-de-un-sommelier-en-el-restaurante-js" */),
  "component---src-pages-blog-principales-funciones-de-un-sous-chef-en-el-restaurante-js": () => import("./../../../src/pages/blog/principales-funciones-de-un-sous-chef-en-el-restaurante.js" /* webpackChunkName: "component---src-pages-blog-principales-funciones-de-un-sous-chef-en-el-restaurante-js" */),
  "component---src-pages-blog-punto-de-venta-para-abarrotes-js": () => import("./../../../src/pages/blog/punto-de-venta-para-abarrotes.js" /* webpackChunkName: "component---src-pages-blog-punto-de-venta-para-abarrotes-js" */),
  "component---src-pages-blog-servicio-de-repartidores-en-moto-para-restaurantes-js": () => import("./../../../src/pages/blog/servicio-de-repartidores-en-moto-para-restaurantes.js" /* webpackChunkName: "component---src-pages-blog-servicio-de-repartidores-en-moto-para-restaurantes-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-apicbase-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-apicbase.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-apicbase-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-bevspot-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-bevspot.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-bevspot-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-flipdish-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-flipdish-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-flipdish-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-gloriafood-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-gloriafood-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-gloriafood-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-go-tab-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-go-tab-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-go-tab-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-just-eat-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-just-eat-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-just-eat-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-lightspeed-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-lightspeed-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-lightspeed-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-menubly-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-menubly-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-menubly-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-olaclick-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-olaclick-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-olaclick-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-pop-menu-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-pop-menu-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-pop-menu-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-restaurant-365-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-restaurant365.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-restaurant-365-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-restaurantify-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-restaurantify-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-restaurantify-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-spot-on-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-spot-on-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-spot-on-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-takeapp-for-business-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-takeapp-for-business.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-takeapp-for-business-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-toast-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-toast-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-toast-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-touch-bistro-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-touch-bistro-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-touch-bistro-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-tryotter-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-tryotter-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-tryotter-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-up-menu-for-restaurants-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-up-menu-for-restaurants.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-up-menu-for-restaurants-js" */),
  "component---src-pages-blog-the-best-free-alternative-to-xtrachef-js": () => import("./../../../src/pages/blog/the-best-free-alternative-to-xtrachef.js" /* webpackChunkName: "component---src-pages-blog-the-best-free-alternative-to-xtrachef-js" */),
  "component---src-pages-blog-vantagens-de-ter-um-menu-digital-para-o-seu-restaurante-js": () => import("./../../../src/pages/blog/vantagens-de-ter-um-menu-digital-para-o-seu-restaurante.js" /* webpackChunkName: "component---src-pages-blog-vantagens-de-ter-um-menu-digital-para-o-seu-restaurante-js" */),
  "component---src-pages-blog-ventajas-de-tener-un-menu-digital-para-tu-cafeteria-js": () => import("./../../../src/pages/blog/ventajas-de-tener-un-menu-digital-para-tu-cafeteria.js" /* webpackChunkName: "component---src-pages-blog-ventajas-de-tener-un-menu-digital-para-tu-cafeteria-js" */),
  "component---src-pages-blog-ventajas-de-tener-un-menu-digital-para-tu-restaurante-js": () => import("./../../../src/pages/blog/ventajas-de-tener-un-menu-digital-para-tu-restaurante.js" /* webpackChunkName: "component---src-pages-blog-ventajas-de-tener-un-menu-digital-para-tu-restaurante-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-cookies-policy-js": () => import("./../../../src/pages/legal/cookies-policy.js" /* webpackChunkName: "component---src-pages-legal-cookies-policy-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-and-conditions-js": () => import("./../../../src/pages/legal/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-js" */),
  "component---src-pages-plan-business-index-js": () => import("./../../../src/pages/plan_business/index.js" /* webpackChunkName: "component---src-pages-plan-business-index-js" */),
  "component---src-pages-plans-index-js": () => import("./../../../src/pages/plans/index.js" /* webpackChunkName: "component---src-pages-plans-index-js" */),
  "component---src-pages-promoters-index-js": () => import("./../../../src/pages/promoters/index.js" /* webpackChunkName: "component---src-pages-promoters-index-js" */),
  "component---src-pages-qr-index-js": () => import("./../../../src/pages/qr/index.js" /* webpackChunkName: "component---src-pages-qr-index-js" */),
  "component---src-pages-sistema-de-reservas-en-linea-index-js": () => import("./../../../src/pages/sistema-de-reservas-en-linea/index.js" /* webpackChunkName: "component---src-pages-sistema-de-reservas-en-linea-index-js" */),
  "component---src-pages-terminal-punto-de-venta-index-js": () => import("./../../../src/pages/terminal-punto-de-venta/index.js" /* webpackChunkName: "component---src-pages-terminal-punto-de-venta-index-js" */),
  "component---src-pages-variant-index-tsx": () => import("./../../../src/pages/variant/index.tsx" /* webpackChunkName: "component---src-pages-variant-index-tsx" */)
}

